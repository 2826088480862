<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t('messages.factoryName') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-autocomplete
              v-model="customer"
              :items="customers"
              :item-text="item => item.code == null ? item.name : item.code +' - '+ item.name"
              item-value="id"
              :no-data-text="$t('table.messages.no_data')"
              :disabled="userType === 3"
              clearable
              single-line
              outlined
            />
          </div>
        </v-col>
        <v-col cols="5">
          <div class="lblTitle">
            <h3>{{ $t('messages.shiftPeriod') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="dates"
                  multiple
                  outlined
                  clearable
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 5">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 5"
                      class="grey--text text-caption"
                    >
                      (+{{ dates.length - 5 }} その他)
                    </span>
                  </template>
                </v-combobox>
              </template>
              <v-date-picker
                v-model="dates"
                multiple
                no-title
                scrollable
                locale="ja"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates)"
                >
                  {{ $t('messages.ok') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  {{ $t('messages.cancel') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="d-flex"
        >
          <div class="align-self-center mr-9">
            <v-btn
              large
              color="forth"
              class="pt-0 pl-10 pr-10 white--text d-flex justify-star"
              @click="onSearch()"
            >
              <span class="font-weight-bold text-h5">
                {{ $t('messages.display') }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="userType !== 3"
          cols="2"
        >
          <v-btn
            v-if="!thisCustomer"
            large
            color="forth"
            class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-star"
            @click="openAddShift"
          >
            <span class="font-weight-bold text-h5">
              {{ $t('messages.addShift') }}</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-row class="d-flex justify-end">
            <v-col cols="5">
              <div class="align-self-center">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0"
                  :placeholder="$t('placeholder.shiftList')"
                  outlined
                  clearable
                  @keydown.enter="initialize"
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="onSearch()"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.search') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:[`footer.page-text`]
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:[`item.index`]="props">
              {{ getIndex(props.index) }}
            </template>

            <template v-slot:[`item.name`]="props">
              <span
                class="font-weight-bold textName"
                @click="getDetailPeriodShift(getValueObject(props.item, 'id'))"
              >
                {{ getValueObject(props.item, 'name') }}
              </span>
            </template>

            <template v-slot:[`item.factoryName`]="props">
              {{ getValueObject(props.item,'customer.user.name') }}
            </template>
            <template v-slot:[`item.job`]="props">
              {{ getValueObject(props.item, 'jobNames') }}
            </template>

            <template v-slot:[`item.timeShift`]="props">
              {{ convertTime(getValueObject(props.item, 'createdAt'), 'YYYY/MM/DD HH:mm') }}
            </template>

            <template v-slot:[`item.action`]="props">
              <div
                v-if="!thisCustomer"
                class="d-flex justify-start"
              >
                <div>
                  <v-btn
                    color="primary"
                    class="mr-5"
                    @click="createTableShift(props.item)"
                  >
                    {{ $t('messages.timesheetCreation') }}
                  </v-btn>
                </div>
                <div>
                  <v-icon
                    v-if="getValueObject(props.item, 'submittedAt')"
                    color="#1BC5BC"
                    size="35px"
                  >
                    mdi-email-send
                  </v-icon>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogCreateTable"
      width="500"
    >
      <v-card>
        <validation-observer v-slot="{ handleSubmit }">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(submitCreatTable)"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <h3>タイムシートの日</h3>
                  <validation-provider
                    v-slot="{ errors }"
                    name="nameFile"
                    rules="required"
                  >
                    <v-select
                      v-model="workdateSelect"
                      :items="datesSelect"
                      outlined
                      :error-messages="errors[0]"
                      @change="handleSelectDate()"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>タイムシートの名前</h3>
                  <validation-provider
                    v-slot="{ errors }"
                    name="nameFileCustomer"
                    rules="required"
                  >
                    <v-text-field
                      v-model="nameFileCustomer"
                      name="nameFileCustomer"
                      outlined
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                large
                color="accent"
                type="submit"
              >
                {{ $t('messages.save') }}
              </v-btn>
              <v-btn
                large
                @click="dialogCreateTable = false"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import { convertTime } from '../../util/covertTime'

  export default {
    name: 'ShiftList',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        nameFileCustomer: '',
        workdateSelect: '',
        itemSelect: {},
        thisCustomer: false,
        itemPaginate: [],
        items: [],
        search: '',
        showPickerStartAt: false,
        showPickerEndAt: false,
        showPickerStartAtEdit: false,
        showPickerEndAtEdit: false,
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '5%',
          },
          {
            text: this.$t('table.headers.shiftName'),
            align: 'center',
            value: 'name',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '25%',
          },
          {
            text: this.$t('table.headers.factoryName'),
            align: 'center',
            value: 'factoryName',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '20%',
          },
          {
            text: this.$t('table.headers.job'),
            align: 'center',
            value: 'job',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '15%',
          },
          {
            text: this.$t('table.headers.updatedTime'),
            align: 'center',
            value: 'timeShift',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '25%',
          },
          {
            text: '',
            align: 'center',
            value: 'action',
            class: 'font-weight-bold',
            sortable: false,
            width: '10%',
          },
        ],
        mainHeaders: [],
        options: {},
        editMode: false,
        loading: false,

        snackbarMessage: '',
        snackbar: false,
        job: null,
        customer: null,
        dates: [],
        planStartAt: '',
        planEndAt: '',
        menu: false,
        menuPickerStartAt: false,
        menuPickerEndAt: false,
        datesSelect: [],
        textDate: '',
        customers: [
          {
            id: null,
            name: this.$t('messages.all'),
          },
        ],
        nowDay: moment().format('YYYY-MM-DD'),
        userType: '',
        dialogCreateTable: false,
        customerId: '',
      }
    },
    computed: {
      ...get('shift', ['message', 'status', 'error', 'shiftPeriodList', 'timestampList']),
      customerList: get('customer@list.data.customers'),
      profile: get('user@profile'),
    },
    watch: {
      search (value) {
        if (!value) {
          this.onSearch()
        }
      },
      dates (value) {
        if (value.length === 0) {
          this.onSearch()
        }
      },
      profile (value) {
        if (this.userType === 3) {
          this.customers = [
            {
              id: this.customerId,
              name: value.name,
            },
          ]
          this.customer = this.customerId
        }
      },
      shiftPeriodList (value) {
        this.$set(this, 'items', value)
      },
      dialogCreateTable (value) {
        if (value) {
          this.$store.dispatch('shift/getPeriodTimestamps', this.itemSelect.id)
        } else {
          this.workdateSelect = ''
          this.nameFileCustomer = ''
        }
      },
      timestampList (value) {
        this.$set(this, 'datesSelect', value)
        this.workdateSelect = this.datesSelect[0]
        this.textDate = this.workdateSelect ? moment(this.workdateSelect).format('YYYY年MM月DD日') : ''
        this.nameFileCustomer = this.workdateSelect ? _.get(this.itemSelect.customer.user, 'name') + '_' + this.textDate + '_' + 'タイムシート' : ''
      },
      customerList (value) {
        value.forEach(item => {
          this.customers.push(item)
        })
      },
      customer () {
        this.options.page = 1
        this.initialize()
      },
      checkboxTimeShift (value) {
        if (value) {
          const shift = this.shiftList.find(item => item.id === this.shiftId)
          if (shift) {
            this.planStartAt = _.get(shift, 'shiftStartAt', '00:00')
            this.planEndAt = _.get(shift, 'shiftEndAt', '00:00')
          }
        } else {
          this.planStartAt = ''
          this.planEndAt = ''
        }
      },
      message (value) {
        if (value === 'success') {
          this.snackbarMessage('messages.success')
          this.initialize()
        }
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
    created () {
      this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      this.customerId = JSON.parse(localStorage.getItem('user')).detail.typeCorrespondId

      if (this.userType === 1 || this.userType === 2) {
        this.getCustomers()
        this.initialize()
      }

      if (this.userType === 3) {
        this.getUser()
        this.thisCustomer = true
      }
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
    },

    methods: {
      convertTime,
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      getNameParttime (parttime) {
        return _.get(parttime, 'manageId') + ' - ' + _.get(parttime, 'name', '')
      },
      getDetailPeriodShift (id) {
        this.$router.push('/shift-information-display/' + id)
      },
      getParttimer () {
        this.$store.dispatch('parttimer/getParttimers')
      },
      getCustomers () {
        this.$store.dispatch('customer/getCustomers')
      },
      pad (d) {
        return d < 10 ? '0' + d.toString() : d.toString()
      },
      initialize () {
        const tmpOptions = { ...this.options }

        if (this.userType === 3) {
          if (this.customer) {
            tmpOptions.customerId = this.customer
          }
          tmpOptions.isSubmittedOnly = true
        }

        if (this.dates.length > 0) {
          tmpOptions.timestamps = JSON.stringify(this.dates)
        }

        if (this.customer) {
          tmpOptions.customerId = this.customer
        }

        if (this.search) {
          tmpOptions.keyword = this.search.trim()
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('shift/getPeriodShift', tmpOptions)
      },
      getNameShift (shift) {
        if (_.get(shift, 'shiftStartAt', '') === '') {
          return '00:00 ~ 00:00'
        }
        return _.get(shift, 'shiftStartAt', '00:00') + ' ~ ' + _.get(shift, 'shiftEndAt', '00:00')
      },
      handleSelectDate () {
        if (!this.workdateSelect) {
          this.workdateSelect = this.datesSelect[0]
        }
        this.textDate = moment(this.workdateSelect).format('YYYY年MM月DD日')
        this.nameFileCustomer = _.get(this.itemSelect.customer.user, 'name') + '_' + this.textDate + '_' + 'タイムシート'
      },
      close () {
        this.dialog = false
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = ''
        })
      },
      deleteItemConfirm () {
        this.$store.dispatch('plan/delete', {
          id: this.editedIndex,
        })
        this.close()
        setTimeout(() => {
          this.initialize()
        }, 1000)
      },
      onSearch () {
        if (this.dates.length !== 0 || this.search) {
          this.options.page = 1
        }
        this.initialize()
      },
      paginate (array, pageSize, pageNumber) {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getDate (value) {
        return moment(value).format('YYYY-MM-DD')
      },
      openAddShift () {
        this.$router.push({ path: '/shift-information-display' })
      },
      async getUser () {
        await this.$store.dispatch('user/getProfile')
      },
      createTableShift (value) {
        this.dialogCreateTable = true
        this.itemSelect = value
      },
      closeTableShift () {
        this.itemSelect = {}
        this.datesSelect = []
        this.workdateSelect = ''
        this.dialogCreateTable = false
      },
      submitCreatTable () {
        const payload = {
          name: this.nameFileCustomer,
          customerName: _.get(this.itemSelect.customer.user, 'name'),
          workdate: this.workdateSelect,
          shiftPeriodId: this.itemSelect.id,
        }
        this.$router.push({ path: '/detail-time-sheet', query: payload })
      },
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12) !important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

  .lblTitle
    color: #5CA6D2

.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px

.titleFlex
  display: flex
  align-items: center

.btnFlex
  display: flex
  align-items: center

  button
    margin: 0px !important

.shiftAt
  margin-left: 0px !important
.v-dialog
  overflow-y: hidden!important
.lblText
  color: #25ACD8
.borderDialog
  margin-top: 24px
  border: 1px solid #25ACD8
  border-radius: 5px
  padding: 10px
.iconSuccess__lange
  font-size: 70px
.textName
  cursor: pointer
  color: #37CCC5
</style>
